<template>
    <div class="wrok-theme-create">
        <div class="manage-wrapper">
            <div class="content">
                <div class="myTabs">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="创建报告" name="kimi">
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="box flex-between">
                    <div class="user_box flex-column" v-if="$route.query.reportType == 3">
                        <div class="title">学员名单</div>
                        <el-input placeholder="请输入学员姓名" size="small" prefix-icon="el-icon-search" v-model="queryName"
                            @input="nameChange">
                        </el-input>
                        <div class="user_item flex-align" :class="{ 'user_item_active': user_index == index }"
                            v-for="(item, index) in stuList" :key="index" @click="selectUser(item, index)">
                            <img src="@/assets/images/home/avatar.png" alt="">
                            <span class="wes">{{ item.name }}</span>
                        </div>
                        <div class="pagination">
                            <el-pagination layout="prev,pager,next" small :total="allSize" :pager-count="5"
                                :page-size="pagesize" @size-change="handleSizeChange" :current-page.sync="currentPage"
                                @current-change="handleCurrentChange">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="kimi_box">
                        <div class="kimi_content" v-loading="loading">
                            <div class="kimi_item flex-column" v-for="(item, index) in kimiList" :key="index">
                                <div class="user flex-layout" v-if="item.user_detail">
                                    <div class="user_detail">{{ item.user_detail }}</div>
                                    <img src="@/assets/images/home/avatar.png" alt="">
                                </div>
                                <div class="kimi flex-layout" v-if="item.kimi_detail">
                                    <img src="@/assets/kimi.png" alt="">
                                    <div class="kimi_detail">
                                        <vue-markdown :source="item.kimi_detail"></vue-markdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kimi_input flex-align">
                            <el-input v-model="input" placeholder="随时@你想要的Kimi+ 使用各种能力"
                                @keyup.enter.native="inputChange"></el-input>
                            <div class="menu_box flex-align">
                                <el-tooltip content="开启新会话" placement="top">
                                    <i class="el-icon-chat-dot-round" @click="openNewTab"></i>
                                </el-tooltip>
                                <el-tooltip content="上传" placement="top">
                                    <el-upload :on-change="handleChange" action="" :auto-upload="false"
                                        :on-preview="handlePreview" :show-file-list="false">
                                        <i class="el-icon-folder"> </i>
                                    </el-upload>
                                </el-tooltip>
                                <el-tooltip content="发送" placement="top">
                                    <i class="el-icon-position" @click="inputChange"></i>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="file_box" v-if="fileList.length > 0">
                            <div class="file_item flex-align" v-for="(item, index) in fileList" :key="index">
                                <div class="flex-align">
                                    <i class="el-icon-tickets"></i>
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="history_box" v-if="historyList.length > 0">
                        <div class="title">历史记录</div>
                        <div class="history_item" v-for="(item, index) in historyList"
                            :class="{ 'history_item_active': history_index == index }" :key="index"
                            @click="selectHistory(item, index)">
                            <span class="wes-2">{{ item.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import SparkMD5 from "spark-md5";
let OSS = require('ali-oss')
export default {
    components: {
        VueMarkdown
    },
    data() {
        return {
            activeName: 'kimi',
            userInfo: this.$store.state.user,
            loading: false,
            input: '',
            queryName: '',
            kimiList: [
                {
                    user_detail: '',
                    kimi_detail: ''
                }
            ],
            fileList: [],
            options: {
                chunkNumber: null, //当前文件块，从1开始
                chunkSize: 10 * 1024 * 1024, //分块大小
                currentChunkSize: null, //当前分块大小
                totalSize: null, //总大小
                identifier: null, //文件标识
                currentIdentifier: null, //当前文件标识
                filename: null, //文件名
                relativePath: null, //文件路径（阿里云路径）
                totalChunks: null, //总块数
                type: null, //文件类型
                chunkData: null //文件数据
            },
            type: '',
            allSize: 0,
            user_index: 0,
            history_index: 0,
            historyList: [],
            pagesize: 7,
            currentPage: 1,
            stuList: [],
            currentUserId: '',
            parentId: ''
        }
    },
    methods: {
        inputChange() {
            if (!this.input.trim()) {
                this.input = ''
                return
            }
            let item = {
                user_detail: this.input,
                kimi_detail: ''
            }
            this.kimiList = this.kimiList.concat(item);
            setTimeout(() => {
                this.loading = true;
                this.sentMsg(this.input);
                this.input = '';
            }, 500)
        },
        async getKimiResult() {
            let data = {
                reportId: this.$route.query.reportId,
                msgId: this.$route.query.rootMsgId
            }
            // this.loading = true;
            let resData = await this.$Api.kimi.getReportResult(data);
            console.log('kimi结果:', resData);
            if (!resData.data[0].content) {
                this.$set(this.kimiList, 0, { ...this.kimiList[0], kimi_detail: '请稍后再试' })
            } else {
                this.$set(this.kimiList, 0, { ...this.kimiList[0], kimi_detail: resData.data[0].content })
            }
            // this.loading = false;
        },
        async sentMsg(content) {
            let data = {
                reportId: this.$route.query.reportId,
                parentId: this.parentId,
                content
            }
            let resData = await this.$Api.kimi.sentMsg(data);
            console.log('kimi聊天结果:', resData);
            if (!resData.data[0].content) {
                this.$set(this.kimiList, this.kimiList.length - 1, { ...this.kimiList[this.kimiList.length - 1], kimi_detail: '请稍后再试' })
            } else {
                this.$set(this.kimiList, this.kimiList.length - 1, { ...this.kimiList[this.kimiList.length - 1], kimi_detail: resData.data[0].content })
            }
            this.loading = false;
        },
        openNewTab() {
            this.$message({
                message: '暂未开放',
                type: 'warning'
            });
        },
        handlePreview() {
            console.log('点击文件列表中已上传的文件时的钩子')
        },
        handleChange(file, fileList) {
            console.log('file:', file)
            // this.fileList = fileList;
            this.computeMD5(file.raw, file);
        },
        async getQueryToken() {
            let resData = await this.$Api.OssUpload.getQueryToken()
            this.client = new OSS({
                // secure:true,
                region: 'oss-cn-beijing', //根据那你的Bucket地点来填写
                accessKeyId: resData.data.accessKeyId, //自己账户的accessKeyId
                accessKeySecret: resData.data.accessKeySecret, //自己账户的accessKeySecret
                bucket: resData.data.bucket, //bucket名字
                stsToken: resData.data.stsToken
            });

        },
        computeMD5(fileRaw, file) {
            let that = this;
            let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
            let chunkSize = 10 * 1024 * 1024
            let chunks = Math.ceil(file.size / chunkSize)
            let currentChunk = 0
            let spark = new SparkMD5.ArrayBuffer()
            let fileReader = new FileReader()

            fileReader.onload = function (e) {
                spark.append(e.target.result)
                currentChunk++
                if (currentChunk < chunks) {
                    loadNext()
                    console.log(
                        `第${currentChunk}分片解析完成, 开始第${currentChunk +
                        1} / ${chunks}分片解析`
                    );
                } else {
                    let md5 = spark.end(); //得到md5
                    function getCaption(obj) {
                        var index = obj.lastIndexOf(".");
                        obj = obj.substring(index + 1, obj.length);
                        return obj;
                    }
                    let filemd5 = md5 + "_" + file.size + "_" + getCaption(file.name);
                    that.options.currentIdentifier = md5;
                    that.options.identifier = filemd5;
                    that.options.totalSize = file.size;
                    that.options.filename = file.name;
                    that.options.totalChunks = currentChunk;
                    that.options.type = getCaption(file.name);
                    // that.getCheck(filemd5, file)
                    console.log('computed hash', filemd5)
                    that.handleHttpRequest(file.raw)
                }
            }
            fileReader.onerror = function () {
                console.warn('FileReader error.')
            }
            function loadNext() {
                let start = currentChunk * chunkSize
                let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
                // 注意这里的 fileRaw
                fileReader.readAsArrayBuffer(blobSlice.call(fileRaw, start, end))
            }
            loadNext()
        },
        //简单上传 
        async handleHttpRequest(file) { //上传OSS
            console.log('++++++', file)
            try {
                // 简单上传文件
                let filePath = 'upload/' + this.dayjs(parseInt(new Date().getTime())).format('YYYY/MM/DD') + '/' + this
                    .options.identifier + '/' + this.options.filename;
                const result = await this.client.put(filePath, file)
                this.type = file.type;
                console.log('123', result)
                this.postMerge(this.options, filePath, file)
            } catch (e) {
                console.error(e);
                if (e.code === 'ConnectionTimeoutError') { // 请求超时异常处理
                    this.uploadStatus = 'exception'
                    console.log("TimeoutError");
                }
            }
        },
        async postMerge(options, filePath, file) {
            let data = {
                filename: options.filename,//文件名
                identifier: options.identifier,//文件标识
                totalSize: options.totalSize,//总大小
                type: this.type,//请求类型
                location: filePath,//文件路径（阿里云路径
                ext: options.type, //文件类型
                projectId: this.$store.state.user.currentProject.id,
                // sourceType: this.sourceType
            }
            let resData = await this.$Api.OssUpload.postMerge(data)
            this.Completeinfo(file)
        },
        async Completeinfo(file) {
            // console.log('file2:', file)
            this.$message({
                message: '上传成功',
                type: 'success'
            });
            let data = {
                file: file,
                identify: this.options.identifier,
                reportId: this.$route.query.reportId,
                msgId: this.parentId
            }
            let resData = await this.$Api.kimi.uploadFileToKiMi(data);
            console.log('上传文件到kimi结果:', resData)
            this.fileList.push(file.name)
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.user_index = 0;
            this.reportUserList();
        },
        handleSizeChange() {

        },
        // 选择用户
        selectUser(item, index) {
            this.user_index = index;
            this.currentUserId = item.id;
            this.kimiList = [
                {
                    user_detail: '',
                    kimi_detail: ''
                }
            ],
            this.msgHistoryList()
        },
        // 选择历史记录
        async selectHistory(item, index) {
            this.history_index = index;
            this.parentId = item.id;
            let data = {
                msgId: item.id,
                page: 1,
                limit: 1000
            }
            let resData = await this.$Api.kimi.msgListOne(data);
            console.log('单个历史记录:', resData);
            if (resData.data.records.length <= 0) {
                return
            }
            this.kimiList = [];
            resData.data.records.map(item => {
                let obj = {
                    user_detail: '',
                    kimi_detail: ''
                }
                if (item.role == 'assistant') {
                    obj.kimi_detail = item.content
                } else {
                    obj.user_detail = item.content
                }
                this.kimiList.push(obj)
            })
        },
        // 获取历史记录
        async msgHistoryList() {
            let data = {
                reportId: this.$route.query.reportId, //
                userId: this.$route.query.reportType == 3 ? this.currentUserId : '',
                page: 1,
                limit: 10
            }
            let resData = await this.$Api.kimi.msgHistoryList(data);
            console.log('历史记录:', resData);
            this.historyList = resData.data.records;
            if (resData.data.records.length == 0) {
                return
            }
            this.selectHistory(resData.data.records[0], 0)
            this.parentId = resData.data.records[0].id
        },
        // 获取学员名单
        async reportUserList() {
            let data = {
                reportId: this.$route.query.reportId,
                page: this.currentPage,
                limit: this.pagesize,
                queryName: this.queryName
            }
            let resData = await this.$Api.kimi.reportUserList(data);
            console.log('学员列表:', resData);
            this.allSize = resData.data.total;
            this.stuList = resData.data.records;
            this.currentUserId = resData.data.records[0].id;
            this.msgHistoryList();
        },
        nameChange(name) {
            this.queryName = name.trim();
            this.currentPage = 1;
            this.reportUserList()
        }
    },
    mounted() {
        this.getQueryToken();
        if (this.$route.query.reportType == 3) {
            this.reportUserList();
            this.getKimiResult();
        } else {
            this.msgHistoryList();
            if (this.$route.query.isView == 'false') {
                this.getKimiResult();
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./less/create_kimi.less');
</style>